.nameScroller {
    margin-right: 10px;
    font-weight: 700;
    width: 200px;
    -webkit-mask-image: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0) 10px,
            rgba(0, 0, 0, 1) 15px,
            rgba(0, 0, 0, 1) 235px,
            rgba(0, 0, 0, 0)
    );
    -ms-overflow-style: none;
    scrollbar-width: none;

}
.nameScroller::-webkit-scrollbar {
     display: none;
 }

.nameHolder {
    white-space: nowrap;
}

.name {
    margin-left: 15px;
    margin-right: 20px;
}

.change {
    width: 17px;
    height: 17px;
    margin-right: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff9900;
    flex-shrink: 0;
    align-self: center;
    position: relative;
}
.change.up {
     color: #3de160;
 }

.change.down {
     color: #f20025;
     transform: rotate(180deg);
 }
